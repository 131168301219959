<template>
  <v-container id="reset" class="fill-height justify-center" tag="section">
    <v-row justify="center">
      <v-col lg="5" sm="8" xl="7">
        <v-card class="elevation-16">
          <v-row>
            <v-col>
              <div class="pa-7 pa-sm-12">
                <v-img
                  src="@/assets/images/cadeado.png"
                  max-height="200px"
                  contain
                ></v-img>
                <h2
                  class="font-weight-bold mt-4 blue-grey--text text--darken-2 text-center"
                >
                  Esqueceu a senha?
                </h2>
                <h6 class="subtitle-2" style="color: #40545d">
                  Insira o seu usuário, e-mail, CPF ou CNPJ e enviaremos um link
                  para você voltar a acessar a sua conta.
                </h6>
                <v-form
                  ref="formReset"
                  v-model="valid"
                  lazy-validation
                  action="/auth/sendSuccess"
                >
                  <v-text-field
                    v-model="user.username"
                    :rules="usernameRules"
                    required
                    outlined
                    label="Usuário, E-mail, CPF ou CNPJ"
                    class="mt-4 login-input-reset-text-transform"
                  ></v-text-field>
                  <v-btn
                    :disabled="!valid"
                    color="info"
                    block
                    class="mr-4"
                    submit
                    @click="submit"
                    :loading="loading"
                    ><i class="fa fa-paper-plane mr-3"></i>Enviar</v-btn
                  >
                </v-form>
                <v-form
                  ref="formSendReturn"
                  v-model="valid"
                  lazy-validation
                  action="/auth/login"
                >
                  <v-btn
                    color="second"
                    block
                    class="mr-4 mt-8"
                    submitReturn
                    @click="submitReturn"
                    >Voltar ao Login</v-btn
                  >
                </v-form>
              </div>
              <v-snackbar
                v-model="snackbar"
                centered
                :color="snackbar_color"
                elevation="12"
              >
                {{ snackbarMsg }}
                <br />
                {{ snackbarSubMsg }}
                <template v-slot:action="{ attrs }">
                  <v-btn text v-bind="attrs" @click="snackbar = false">
                    Fechar
                  </v-btn>
                </template>
              </v-snackbar>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import User from '~/models/user'
import { mapActions } from 'vuex'
export default {
  data: () => ({
    loading: false,
    snackbar: false,
    snackbar_color: 'error',
    snackbarMsg: '',
    snackbarSubMsg: '',
    valid: true,
    user: {},
    username: '',
    usernameRules: [(v) => !!v || 'Campo obrigatório'],
  }),
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push({ path: '/' })
    }
  },
  methods: {
    ...mapActions('auth', ['reset']),
    async submit() {
      this.snackbar_color = 'error'
      this.snackbarMsg = ''
      this.snackbarSubMsg = ''
      this.loading = true
      this.$refs.formReset.validate()

      if (this.$refs.formReset.validate(true)) {
        if (this.user.username) {
          try {
            let returnUser = await this.reset(this.user)
            if ('error' in returnUser) {
              this.snackbarMsg = returnUser.error
              this.snackbar = true
            } else {
              this.snackbarMsg = `Email enviado! Verifique se email está correto!`
              this.snackbarSubMsg = returnUser.email
              this.snackbar = true
              this.snackbar_color = 'success'
              setTimeout(() => {
                this.$router.push({ path: '/auth/sendSuccess' })
              }, 5000)
            }
          } catch (error) {
            console.log(error)
            if (error.response && error.response.status == 500) {
              this.snackbarMsg = 'Informação inválida.'
            } else {
              this.snackbarMsg = 'ERRO: Ao enviar e-mail para reset de senha!'
              this.snackbarSubMsg = 'Possívelmente o e-mail não existe.'
            }
            this.snackbar = true
          }
        }
      }
      this.loading = false
    },

    async submitReturn() {
      this.$router.push({ path: '/' })
    },
  },
}
</script>

<style>
.login-input-reset-text-transform input {
  text-transform: none;
}
</style>
